<template>
    
    <div class="grid">
        <div class="col-12">
            <Loading v-if="loading" />
            <div class="card">
                <div>
                    <h5 class="titulo_pedidos">Perfil</h5>
                    <div class="grid">
                        <div class="col-9">
                            <p><label>Nome</label></p>
                            <p v-if="this.modo=='visualizar'"><span class="texto"></span></p>
                            <InputText class="input_text" v-model="nome" v-if="this.modo=='editar'" type="text" />
                        </div>
                        <div class="col-3">
                            <p><label>Data de Nascimento</label></p>
                            <p v-if="this.modo=='visualizar'"><span class="texto"></span></p>
                            <InputText class="input_text" v-model="data_nascimento" v-if="this.modo=='editar'"  />
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4">
                            <p><label>CPF</label></p>
                            <p v-if="this.modo=='visualizar'"><span class="texto"></span></p>
                            <InputText class="input_text" v-model="cpf" v-if="this.modo=='editar'" type="text" />
                        </div>
                        <div class="col-8">
                            <p><label>E-mail</label></p>
                            <p v-if="this.modo=='visualizar'"><span class="texto"></span></p>
                            <InputText class="input_text" v-model="email" v-if="this.modo=='editar'"  />
                        </div>
                    </div>

                    <h5 class="titulo_pedidos">Endereço</h5>
                    <div class="grid">
                        <div class="col-9">
                            <p><label>Logradouro</label></p>
                            <p v-if="this.modo=='visualizar'"><span class="texto"></span></p>
                            <InputText class="input_text" v-model="endereco" v-if="this.modo=='editar'" type="text" />
                        </div>
                        <div class="col-3">
                            <p><label>Número</label></p>
                            <p v-if="this.modo=='visualizar'"><span class="texto"></span></p>
                            <InputText class="input_text" v-model="numero" v-if="this.modo=='editar'" />
                        </div>
                    </div>  
                    <div class="grid">
                        <div class="col-8">
                            <p><label>Bairro</label></p>
                            <p v-if="this.modo=='visualizar'"><span class="texto"></span></p>
                            <InputText class="input_text" v-model="bairro" v-if="this.modo=='editar'"   type="text" />
                        </div>
                        <div class="col-4">
                            <p><label>CEP</label></p>
                            <p v-if="this.modo=='visualizar'"><span class="texto"></span></p>
                            <InputText class="input_text" v-model="cep" v-if="this.modo=='editar'"  />
                        </div>
                    </div>   
                    <h5 class="titulo_pedidos">Alterar Senha</h5>
                    <div class="grid">
                        <div class="col-6">
                            <p><label>Senha</label></p>
                            <input  :type="mostrarSenha ? 'text' : 'password'"  v-model="senha" class="input_password" autocomplete="new-password"/>
                        </div>
                        <div class="col-6">
                            <p><label>Confirmação de Senha</label></p>
                            <input  :type="mostrarSenha ? 'text' : 'password'" v-model="confirma_senha" class="input_password" autocomplete="new-password"/>
                        </div>
                    </div>
                    <label for="mostrarSenha">
                        <input id="mostrarSenha" type="checkbox" v-model="mostrarSenha" />
                        Mostrar senha
                    </label>
                    <div class="div_botoes">
                        <Button label="Salvar" class="p-button-primary btn_success"  @click="salvarPerfil()" />
                        <Button label="Cancelar" class="p-button-primary btn_cancel" @click="cancelar()"/>
                    </div>
                </div>
            </div>
        </div>
        <Toast position="bottom-left" />
    </div>
</template>

<script>
import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { ref } from 'vue';
export default {
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            modo:"editar",//visualizar ou editar
            empresa_id:0,
            representante:null,
            nome: "",
            senha: ref(''),
            senhaValida: ref(false),
            mostrarSenha: ref(false)
        };
    },
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.representante_id = this.$storage.getStorageSync("user").representante_id
        this.empresa_id = this.empresa.id
        this.api = new Api();
    },
    mounted() {
        this.carregarPerfil()
      //  this.$toast.add({ severity: "success", summary: "Sucesso", detail: "Produto Adicionado", life: 3000 });

    },
    methods: {
        carregarPerfil() {
            this.api.carregarRepresentante(this.token,this.representante_id).then(data=>{
                this.nome = data.data.nome
                this.data_nascimento = data.data.data_nascimento
                this.cpf = data.data.cpf
                this.email = data.data.email
                this.endereco = data.data.endereco
                this.bairro = data.data.bairro
                this.numero = data.data.numero
                this.cep = data.data.cep
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        cancelar() {
            this.$router.push('/');
        },
        salvarPerfil() {
            var perfil = {};
            perfil.id = this.representante_id;
            perfil.empresa_id = this.empresa_id;
            perfil.nome = this.nome;
            perfil.email = this.email
            perfil.cpf = this.cpf;
            perfil.data_nascimento = this.data_nascimento;
            perfil.endereco = this.endereco;
            perfil.bairro = this.bairro;
            perfil.numero = this.numero;
            perfil.cep = this.cep;


            if (this.senha != "" && this.senha != undefined) {
                if (this.senha != this.confirma_senha) {
                    this.$toast.add({ severity: "error", summary: "Erro!", detail: "As senhas devem ser iguais.", life: 3000 });
                    return;
                } else {
                    perfil.senha = this.senha;
                }
            }

            this.api.updatePerfil({ token: this.token, perfil }).then(data => {
                if (data.data.verifica_erro == false) {
                    this.$router.push({ name: 'dashboard' })
                } else {
                    alert(this.msg_erro);
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        salvarUsuario(){
            this.api.atualizarUsuario(this.token,this.representante_id,this.nome, this.email,this.password).then(data=>{
                console.log(data)
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        }
    },
    components: { Loading }
}
</script>
<style scoped>
    .div_botoes{
        margin-top:15px;
        margin-bottom:10px;
        width:100%;
        text-align:center;
    }
    .btn_success{
        width: 15rem;
    }
    .btn_cancel{
    background-color:#9d9d9d; 
    border:#9d9d9d solid 1px;
    margin-left:10px;
    width: 15rem;
    }
    .btn_cancel:hover{
        background-color:#9d9d9d; 
        border:#9d9d9d solid 1px;
    }
    .input_text{
        width:100%;
    }

    .input_password{
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 1rem;
        color: #495057;
        background: #ffffff;
        padding: 0.75rem 0.75rem;
        border: 1px solid #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px;
        width:100%;
    }
</style>